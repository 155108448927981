import './../../styles/contenido.css';

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Loading from '../../components/loading/LoadingDetalle';

const FormacionDetail = ({ backgroundImage }) => {

    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(true);
    //-----------------Gestión datos--------------------
    //Valores de la formación
    const [formacion, setFormacion] = useState();
    //-----------------Accedemos al id de la url--------
    const { id } = useParams();

    //Extraer los nombres de las categorías
    const categorias = formacion ? formacion.categorias.map((categoria) => categoria.nombre_categoria).join(' | ') : '';


    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Pedimos sólo el curso que se nos ha pasado por url para modificar
        fetch('https://dev-backend.fcf.services/formacion/curso/' + id, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    //Inicializamos la formación con los nuevos datos modificados
                    setFormacion(data.result[0]);
                    setLoading(false);
                }
            });

    }, []);

    return (
        <React.Fragment>
        {
        (loading) ? <Loading /> :
            <div className='bg-noticia mt-20 flex justify-center items-start min-h-screen' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
                <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4 '>
                    <div className='w-full mx-auto sm:h-60 md:h-96 lg:h-96 xl:h-96'>
                        <img src={formacion.imagen}  alt='Imagen del curso' className='w-full shadow-lg object-cover sm:h-60 md:h-96 lg:h-96 xl:h-96'/>
                    </div>
                    <div className='flex justify-center items-center sm:mt-0 md:-mt-20 lg:-mt-20 xl:-mt-20 2xl:-mt-20 bg-noticia'>
                        <div id="formacion" className='md:w-3/4 pb-10'>                        
                            <div className='bg-noticia'>
                                <div className='pl-10 pr-10 pt-5 text-smallTitle md:text-customTitle text-customGray font-oswald font-bold uppercase'>{formacion.titulo}</div>
                            </div>
                            <div className='flex flex-row mt-4 pl-10 pr-10 justify-between items-center'>
                                <span className='text-customBlue font-oswald text-s font-normal uppercase relative block'>{categorias}</span>
                                <span id="precio" className='text-customGray font-oswald text-3xl font-bold text-right'>Preu: {formacion.precio}€</span>
                            </div>                           
                            { (formacion.antetitulo !== '...') && 
                                <p className='m-10 font-montserrat bg-opacity-10 text-m text-justify leading-7 font-normal italic bg-gray-500 p-4'>{formacion.antetitulo}</p>
                            }
                            <p className='pl-10 pr-10 font-montserrat text-m font-normal mt-4 text-justify tracking-wider leading-8' id="contenido" dangerouslySetInnerHTML={{ __html: formacion.contenido }}
                            ></p>
                        </div>                        
                    </div>
                </div>
            </div>
        }
        </React.Fragment>
    );
}

export default FormacionDetail;