import React, { useEffect, useState } from 'react';

const Menu = ({ setCategoriaSeleccionada, categoriaSeleccionada }) => {

    //-----------------Gestión datos--------------------
    const [categorias, setCategorias] = useState([]);

    const handleCategoriaClick = (categoria) => {
        setCategoriaSeleccionada(categoria);
    }

    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Obtenemos todas las categorías disponibles
        fetch('https://dev-backend.fcf.services/formacion/categorias', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const categoriasOrdenadas = data.result.sort((a, b) => {
                        const orden = ['Futbol', 'Futbol Sala', 'Online'];
                        return orden.indexOf(a.nombre_categoria) - orden.indexOf(b.nombre_categoria);
                    });
                    setCategorias(categoriasOrdenadas);
                }
            })
            .catch((error) => {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
            });

    }, []);
    

    return (
        <React.Fragment>
                <div className='flex justify-center items-center flex-col pt-1'>
                    <div className='font-oswald mb-14 flex flex-wrap justify-center'>
                        <button key="0" 
                            className={`uppercase w-40 h-8 m-2 md:text-xl md:w-52 md:h-12 md:m-4 bg-customBlue text-white ${categoriaSeleccionada === 0 ? 'opacity-100' : 'opacity-50'}`}
                            onClick={() => setCategoriaSeleccionada(0)}
                        >Tots</button>
                        {
                            categorias.map((categoria) => (
                                <button
                                key={categoria.id_categoria}
                                onClick={() => handleCategoriaClick(categoria.id_categoria)}
                                className={`uppercase w-40 h-8 m-2 md:text-xl md:w-52 md:h-12 md:m-4 bg-customBlue text-white ${categoria.id_categoria === categoriaSeleccionada ? 'active opacity-100' : 'opacity-50'}`}
                                >
                                {categoria.nombre_categoria}
                                </button>
                            ))
                        }
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Menu;