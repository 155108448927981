import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import FormacionsGrid from './views/formacio/formacionsGrid';
import FormacionDetail from './views/formacio/formacionDetail';
import NoticiesGrid from './views/noticies/noticiesGrid';
import ErrorBoundary from './components/errorBoundary';
import NoticiaDetail from './views/noticies/NoticiaDetail';
import backgroundImage from './images/trama.png';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <React.Fragment>
          <Header/>
            <Routes>
              <Route path="/" element={<FormacionsGrid backgroundImage={backgroundImage}/>}/>
              <Route path="/curs/:id" element={<FormacionDetail backgroundImage={backgroundImage}/>}/>
              <Route path="/noticies" element={<NoticiesGrid backgroundImage={backgroundImage}/>}/>
              <Route path="/noticia/*" element={<NoticiaDetail backgroundImage={backgroundImage}/>}/>
            </Routes>
          <Footer/>
        </React.Fragment>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
