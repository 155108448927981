import React, { useState } from 'react';

const Curso = ({formacion}) => {  

    const [hovered, setHovered] = useState(false);

    // URL de la imagen por defecto
    const imagenPorDefecto = 'http://files.fcf.cat/img/imatge-base-formacio.jpg';

    //Extraer los nombres de las categorías
    const categorias = formacion.categorias.map((categoria) => categoria.nombre_categoria).join(' | ');

    return (
        <React.Fragment>
            <div id='contenedor' className="text-white h-80 w-full m-2 p-2 relative overflow-hidden shadow-lg">
                <a href={`/curs/${formacion.id_curso}`}>
                    <div 
                    id='imagen' 
                    className='flex flex-col bg-cover absolute top-0 left-0 w-full h-full' 
                    style={{ backgroundImage: `url(${formacion.imagen}), url(${imagenPorDefecto})`, overflow: 'hidden'}}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    >
                        <div 
                        id='texto' 
                        className={`-mt-28 overflow-hidden absolute bg-black bg-opacity-70 transition duration-200 ease-in-out transform ${
                            hovered ? 'mt-6 flex flex-col justify-center ' : 'translate-y-full' 
                        } mx-4 px-4`}
                        style={{ height: '19rem', overflow: 'hidden'}}
                        >
                            <div className='flex flex-col relative '>
                                <div className="absolute top-1/2 w-5 h-0.5 bg-white transform -translate-y-1/2"></div>
                                <p className='font-oswald mt-2 ml-8 uppercase'>{categorias}</p>                    
                            </div>
                            <div>
                                <h2 className='font-oswald text-2xl font-bold whitespace-normal uppercase'>{formacion.titulo}</h2>
                                { (formacion.antetitulo !== '...') && 
                                    <p className={`font-montserrat mt-2 whitespace-normal line-clamp-4 ${hovered ? '' : 'opacity-0'}`}>{formacion.antetitulo}</p>
                                }
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </React.Fragment>
    );
}

export default Curso;