import React, { useEffect, useState } from 'react';

import Menu from './components/Menu';
import Curso from './components/Curso';
import Loading from '../../components/loading/LoadingFormaciones';

const FormacionsGrid = ({ backgroundImage }) => {

    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(true);
    //-----------------Gestión datos--------------------
    const [data, setData] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(0);

    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Obtenemos todos los cursos
        fetch('https://dev-backend.fcf.services/formacion/cursos', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    data.result.sort((a, b) => {
                        // Ordenamos los datos por categoría y orden
                        const categoryOrder = {
                            "Futbol": 1,
                            "Futbol Sala": 2,
                            "Online": 3
                        };
    
                        // Comparar por categoría
                        const categoryComparison = categoryOrder[a.categorias[0].nombre_categoria] - categoryOrder[b.categorias[0].nombre_categoria];
                        if (categoryComparison !== 0) {
                            return categoryComparison;
                        }
    
                        // Si están en la misma categoría, comparar por orden
                        return a.orden - b.orden;
                    });
                    setData(data.result);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
            });

    }, []);

    return (
        <React.Fragment>
        {
            (loading) ? <Loading backgroundImage={backgroundImage}/> :
            <div className='mt-20 bg-noticia flex justify-center' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
                <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4'>
                <h1 className='text-center font-montserrat font-bold text-customTitle uppercase text-customBlack p-9'>Formació</h1>
                    <Menu setCategoriaSeleccionada={setCategoriaSeleccionada} categoriaSeleccionada={categoriaSeleccionada} />
                    <div id="wrap" className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pb-6'>
                    {
                        data
                        .filter(formacion => formacion.visible !== 0 && (categoriaSeleccionada === 0 || formacion.categorias.some(categoria => categoria.id_categoria === categoriaSeleccionada)))
                        .map((formacion) => (
                            <Curso key={formacion.id_curso} formacion={formacion}/>
                        ))
                    }
                    </div>
                </div>               
            </div>
        }
        </React.Fragment>
    );
}

export default FormacionsGrid;