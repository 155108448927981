import React from 'react';
import { format } from 'date-fns';

const Noticia = ({noticia}) => {  

    // URL de la imagen por defecto
    const imagenPorDefecto = 'http://files.fcf.cat/img/imatge-base-formacio.jpg';

    // Determinamos la URL de la imagen
    const imagenURL = noticia.IMAGEHOME ? noticia.IMAGEHOME : imagenPorDefecto;

    // Definimos el antetítulo
    let antetituloCortado = noticia.ABSTRACT;

    // Si el antetítulo es demasiado largo, lo recortamos
    if (antetituloCortado.length > 190) {
        let pos = antetituloCortado.lastIndexOf(' ', 175);
        if (pos === -1) {
            antetituloCortado = antetituloCortado.slice(0, 190) + ' [...]';
        } else {
            antetituloCortado = antetituloCortado.slice(0, pos) + ' [...]';
        }
    }

    return (
        <React.Fragment>
            <div id='contenedor' className="bg-white w-full h-auto sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/5 m-2 p-2 relative overflow-hidden shadow-lg flex flex-col justify-between" style={{ flex: '1 1 auto' }}>
                <div className='text-left'>
                    <img src={imagenURL} alt='imagenPorDefecto' className='w-full object-cover' style={{height: '200px'}}/>
                    <h2 className='font-oswald text-2xl font-bold whitespace-normal p-2 uppercase'>{noticia.TITULO}</h2>
                    <div className='text-customBlue font-oswald text-s font-normal uppercase relative mt-4 pl-10'>
                        {format(new Date(noticia.FECHA), 'dd/MM/yyyy')}
                        <div className="absolute top-1/2 left-2 w-6 h-0.5 bg-customBlue transform -translate-y-1/2"></div>
                    </div>
                    <div>
                        <p className='font-montserrat p-2'>{antetituloCortado}</p>
                    </div>
                </div>                
                <div className='bottom-0'>
                    <a href={`noticia/${noticia.NOTICIA_URL}`}><button className='font-oswald uppercase bg-customBlue text-white w-40 h-8 m-2 md:text-xl md:w-52 md:h-12 md:m-4'>Llegir més</button></a>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Noticia;