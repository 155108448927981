import React from 'react';
import logo from '../../images/logobn-png-h-no-texto.png';

const Header = () => {

    return (
        <header>
            <div className='bg-customBlue h-20 flex fixed items-center justify-center top-0 w-full z-50'>
                <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4 h-20 '>
                    <a href='https://formacio.fcf.cat/' className='flex h-full'>
                        <img className='p-1 pl-1 pb-2 pt-2 h-full invert' src={logo}/>
                    </a>
                </div>
            </div>        
        </header>
    );
}

export default Header;