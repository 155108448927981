import './../../styles/contenido.css';

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import Loading from '../../components/loading/LoadingDetalle';

const NoticiaDetail = ({ backgroundImage }) => {

    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(true);
    //-----------------Gestión datos--------------------
    //Valores de la formación
    const [noticia, setNoticia] = useState();
    //-----------------Accedemos al id de la url--------
    const { '*': slug } = useParams();

    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Pedimos sólo el curso que se nos ha pasado por url para modificar
        fetch(`https://apidev.fcf.cat/news/get-new/1/${slug}` , requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.result) {
                    //Inicializamos la formación con los nuevos datos modificados
                    setNoticia(data.result);
                    setLoading(false);
                }
            });

    }, []);

    //console.log(loading);
    console.log('slug: '+slug);
    console.log('noticia: '+noticia);
    //console.log(categorias);

    return (
        <React.Fragment>
        {
        (loading) ? <Loading backgroundImage={backgroundImage}/> :
            <div className='bg-noticia mt-20 flex justify-center items-start min-h-screen' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
                <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4'>
                    <div className='w-full mx-auto sm:h-60 md:h-96 lg:h-96 xl:h-96'>
                        <img src={noticia.IMAGEHOME}  alt='Imagen del curso' className='w-full shadow-lg object-cover sm:h-60 md:h-96 lg:h-96 xl:h-96'/>
                    </div>  
                    <div className='flex justify-center items-center sm:mt-0 md:-mt-20 lg:-mt-20 xl:-mt-20 2xl:-mt-20 bg-noticia'>                    
                        <div id="noticia" className='md:w-3/4 pb-10'>                            
                            <div className='bg-noticia'>
                                <h2 className='pl-10 pr-10 pt-5 text-customGray font-oswald md:text-customTitle font-bold text-smallTitle uppercase'>{noticia.TITULO}</h2>
                            </div>
                            <div className='text-customBlue font-oswald text-s font-normal uppercase relative mt-4 pl-10'>
                                {format(new Date(noticia.FECHA), 'dd/MM/yyyy')}
                                {/*<div className="absolute top-1/2 left-10 w-8 h-0.5 bg-customBlue transform -translate-y-1/2"></div>*/}
                            </div>                           
                            <p className='m-10 font-montserrat bg-opacity-10 text-m text-justify leading-7 font-normal italic bg-gray-500 p-4'>{noticia.ABSTRACT}</p>
                            <p 
                                className='pl-10 pr-10 font-montserrat text-m font-normal mt-4 text-justify tracking-wider leading-8' 
                                id="contenido" dangerouslySetInnerHTML={{ __html: noticia.LIN_CUERPO }}
                                
                            ></p>
                        </div>
                    </div>
                </div>                                  
            </div>
        }
        </React.Fragment>
    );
}

export default NoticiaDetail;