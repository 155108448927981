import React, { useState } from 'react';

const LoadingDetalle = ({backgroundImage}) => {
    return (
        <React.Fragment>
            <div className='bg-noticia flex justify-center items-center min-h-screen overflow-hidden mt-20 pb-10' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
              <div className='w-full'>
              <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4 mx-auto '>
                <div className="animate-pulse w-full">
                  <div id="imagen" className=" bg-gray-200 rounded w-full shadow-lg sm:h-60 md:h-96 lg:h-96 xl:h-96"></div>               
                  <div className='flex justify-center items-center m-4'>
                    <div className="md:w-3/4 w-full">
                      <div id="contenedor" className="w-full">
                          <div id="titulo" className='bg-gray-200 pl-10 pr-10 h-24 shadow-lg w-full'></div>
                          <div id="fecha" className='bg-gray-200 pl-10 pr-10 h-10 w-28 mt-5 shadow-lg'></div>
                          <div id="antetitulo" className='bg-gray-200 pl-10 pr-10 h-24 mt-5 shadow-lg w-full'></div>
                          <div id="contenido" className='bg-gray-200 pl-10 pr-10 h-screen mt-10 shadow-lg w-full'></div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </React.Fragment>
    );
}

export default LoadingDetalle;