import React, { useEffect, useState } from 'react';

import Noticia from './components/Noticia';
import Loading from '../../components/loading/LoadingNoticias';

const NoticiesGrid = ({ backgroundImage }) => {

    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(true);
    //-----------------Gestión datos--------------------
    const [data, setData] = useState([]);

    useEffect(() => {
        //Inicializamos el método
        const requestOptions = {
            method: 'GET',
        };

        //Obtenemos todas las noticias
        fetch('https://apidev.fcf.cat/news/news-category/1/3000318/10', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.result) {
                    setData(data.result.NOTICIAS);
                    setLoading(false);
                } else {
                    // Si no se encuentra la propiedad 'NOTICIAS', puedes manejarlo según tu lógica
                    console.error('No se encontraron noticias en la respuesta API.');
                }
            })
            .catch((error) => {
                console.log('Hubo un problema con la petición Fetch:' + error.message);
            });

        }, []);

        console.log(data);


    return (
        <React.Fragment>
        {
            (loading) ? <Loading backgroundImage={backgroundImage}/> :
            <div className='mt-20 pb-10 bg-noticia flex text-center justify-center' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
                <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4 '>
                    <div className='w-full '>
                        <h1 className='font-montserrat font-bold text-customTitle uppercase text-customBlack p-9'>Notícies</h1>
                        <div id="wrap" className='flex flex-wrap mx-auto justify-center pb-6'>
                        {
                            data
                            .map((noticia) => (
                                <Noticia noticia={noticia}/>
                            ))
                        }
                        </div>
                        <a href="https://www.fcf.cat/noticies"><button className='font-oswald uppercase bg-customBlue text-white w-40 h-8 m-2 md:text-xl md:w-52 md:h-12 md:m-4'>Més notícies</button></a>
                    </div>
                </div>
                
            </div>
        }
        </React.Fragment>
    );
}

export default NoticiesGrid;