import React from 'react';
import { RiTwitterXFill, RiFacebookFill, RiInstagramLine, RiYoutubeFill } from "react-icons/ri";

const Footer = () => {

    return (
        <React.Fragment>
            <footer className='font-oswald uppercase color bg-customBlue h-auto bottom-0 left-0 w-full flex items-center justify-center p-4 flex-col md:space-x-4 md:flex-row mt-auto'>
                <div className='p-2 text-white text-xl md:text-2xl flex md:pr-10 md:border-r-2 md:space-x-4'>
                    <ul className='flex space-x-4'>
                        <li><a href="https://formacio.fcf.cat">cursos</a></li>
                        <li><a href="/noticies">notícies</a></li>
                        {/*<li><a href="https://formacio.fcf.cat/contacte">contacte</a></li>*/}
                    </ul>
                </div>
                <div className='p-2 text-white text-2xl md:text-2xl md:px-8'>
                    <ul className='flex space-x-4'>
                        <li><a target="_blank" href="https://www.instagram.com/fcf_cat/"><RiInstagramLine/></a></li>
                        <li><a target="_blank" href="https://www.facebook.com/FCatalanaFutbol/"><RiFacebookFill/></a></li>
                        <li><a target="_blank" href="https://twitter.com/fcf_cat"><RiTwitterXFill/></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/channel/UC8sADOGT8FRHDSInSX7HddA"><RiYoutubeFill/></a></li>
                    </ul>
                </div>
                <div className='p-2 text-gray-400 flex justify-center flex-col items-center md:border-l-2 md:pl-6 md:space-x-4'>
                    <span><a target="_blank" href="https://www.fcf.cat">&copy; fcf 2024</a></span>
                    <span>
                        <ul className='flex space-x-4'>
                            <li><a target="_blank" href="https://www.fcf.cat/avis-legal">Avís Legal</a></li>
                            <li><a target="_blank" href="https://www.fcf.cat/politica-privacitat">Privacitat</a></li>
                            <li><a target="_blank" href="https://www.fcf.cat/cookies">Cookies</a></li>
                        </ul>
                    </span>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;