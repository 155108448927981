import React from 'react';

const LoadingPortada = ({backgroundImage}) => {
    return (
        <React.Fragment>
          <div className='bg-noticia flex items-center justify-center p-6' style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', minHeight: '100vh'}}>
            <div className='w-full'>
            <div className='sm:w-full md:w-4/5 lg:3/4 xl:2/4 2xl:2/4 mx-auto'>
              <div className="animate-pulse w-full">
                <div className="flex justify-center items-center flex-col pt-32">
                  <div className="h-16 bg-gray-200 rounded w-56"></div>
                </div>

                <div className="h-16 bg-gray-200 rounded w-full mt-12"></div>
                
                <div className="flex flex-wrap justify-center">
                  <div id="contenedor" className="text-white w-full h-screen mt-16 relative overflow-hidden shadow-lg">
                    <div className="h-full w-full bg-gray-200">
                      <div id="texto" className="mt-6 overflow-hidden absolute bg-black bg-opacity-70 transition duration-200 ease-in-out transform mx-4 px-4">
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              </div>
            </div>
        </React.Fragment>
    );
}

export default LoadingPortada;